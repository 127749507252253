
  import { defineComponent, ref, reactive, watch, inject, onMounted, nextTick } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import api from "@/api/axios";
  import wx from "weixin-sdk-js";
  import axios from "@/api/axios";
  import useFootPrint from "@/hooks/useFootPrint";
  import SlotFooter from "@/components/common/SlotFooter.vue";
  import Loading from "@/components/UI/Loading";
  import navigatorType from "@/utils/browser";


import calendarFormat from "@/utils/dateFormat";
import Toast from "@/components/UI/Toast/";
import qs from "qs";
import {IproductList, productMap} from "@/hooks/useProduct";

  export default defineComponent({
    name: "productdetail",
    components: {
      SlotFooter,
      Loading,
    },
    setup() {
      const shareTitle = ref('')
      const route = useRoute();
      const router = useRouter();
      const id = ref(route.params.id);
      const classId = ref(route.params.classId);
      const isFav = ref(false);
      const productsVO = ref(null);
      const list = ref([]);
      const secListComp = ref([]);
      const amclass = ref(null);
      const catNo = ref("");
      const suppliercode = ref("");
      const comp = ref({});
      const RelatedProIdList = ref([]);

      const { setFootItem } = useFootPrint();
      const listCompLen = ref(0);
      const  productLotList = ref()
      const lotid = ref()
      const tcid = ref(0)
      const specChecked = ref(true)
      const productPrice = ref()
      const productStock = ref()
      const biaowuPriceOpen = ref()
      const productPdfHref = ref()

     const showModal =ref(false)
      const batchNo = ref("");

      //  展示用户信息
      const userInfo = inject("userInfo") as any;
      function getData(id: any) {
        productsVO.value = null;
        api
                .get("/M/Product/ProductDetail/" + id)
                .then((res): void => {
                  const data = res.data.obj;
                  biaowuPriceOpen.value = data.biaowuPriceOpen
                  if (data.productsVO.nongdu && data.productsVO.nongdu.indexOf('(') != -1) {
                    const index = data.productsVO.nongdu.indexOf('(')
                    data.productsVO.nongdu = data.productsVO.nongdu.substring(0, index)
                  }
                  productsVO.value = data.productsVO;
                  productPdfHref.value = data.productPdfHref
                  document.title = data.productsVO.cnname + "-普天同创";
                  shareTitle.value = data.productsVO.cnname
                  route.meta.title = data.productsVO.cnname
                  route.meta.content.description = data.description;
                  list.value = data.listComp;
                  comp.value = data.comp;
                  isFav.value = data.isFav;
                  amclass.value = data.amclass;
                  catNo.value = data.productsVO.catno;
                  suppliercode.value = data.productsVO.suppliercode;

                  RelatedProIdList.value = data.RelatedProIdList;
                  secListComp.value = data.secListComp;
                  listCompLen.value = data.listComp.length;
                  data.productLotList.length>1 && data.productLotList.forEach((item: any) => {
                    item.specChecked = false
                  })
                  if(data.productLotList.length>1){
                    data.productLotList[0].specChecked = true;
                    lotid.value = data.productLotList[0].lotid
                  }
                  productLotList.value = data.productLotList
                  const isPc = navigatorType();
                  if (isPc) {
                    window.location.href = `https://www.gbw114.com/p${data.productsVO.classId}/p${data.productsVO.id}.html`;
                  }

                  // 判断价格
                  // 判断是否登录
                  const loginUser = data.loginUser

                  if (productLotList.value.length>1) {
                    productStock.value = data.productLotList[0].stock
                    if (data.productsVO.suppliercode == 1) {
                      if (loginUser) {
                        productPrice.value = Math.ceil(Number(data.productLotList[0].outPrice)*(loginUser.discount?loginUser.discount:1))
                      } else {
                        productPrice.value = Math.ceil(Number((data.productLotList[0].outPrice)))
                      }
                    }else{
                      productPrice.value = Math.ceil(Number(data.productLotList[0].outPrice))
                    }

                  }else{
                    productStock.value = (productsVO.value as any).stock
                    if (data.productsVO.suppliercode == 1) {
                      if (loginUser) {
                        productPrice.value =  Math.ceil(Number((productsVO.value as any).retailPrice)*(loginUser.discount?loginUser.discount:1))
                      } else {
                        productPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
                      }
                    }else{
                      productPrice.value = Math.ceil(Number((productsVO.value as any).retailPrice))
                    }
                  }

                })
                .catch((err) => {
                  console.log(err);
                });


      }
      getData(id.value);
      function clickSpec(item: any, index: any) {
        productStock.value = item.stock
        lotid.value = item.lotid;

        productLotList.value.length>1 && productLotList.value.forEach((item: any) => {
          item.specChecked = false
        })
        productLotList.value[index].specChecked = true
        specChecked.value = false

        // 判断是否登录
        if (userInfo) {
          if ((productsVO.value as any).suppliercode == 1) {
            productPrice.value = Math.ceil(Number(item.outPrice)*(userInfo.discount?userInfo.discount:1))
          } else {
            productPrice.value = Math.ceil(Number(item.outPrice))
          }
        } else {
          productPrice.value = Math.ceil(Number(item.outPrice))
        }

      }
      function firstSpec() {
        if(!productLotList.value[0]) {
          return false
        }
        lotid.value = productLotList.value[0].lotid
        specChecked.value = true
        productLotList.value.length>0 && productLotList.value.forEach((item: any) => {
          item.specChecked = false
        })
      }


    function closeModal(){
      this.showModal = false;
    }


        //校验批次号
    function checkBatchNum(batchNum: string) {
      const reg = /^[0-9]{8}$/;
      const reg2 = /^[0-9]{10}$/;
      console.log("batchNum:",batchNum);
      if (batchNum && !(reg.test(batchNum)||reg2.test(batchNum))) {
        Toast({
          type: "warning",
          title: "请输入正确的标签批号",
        });
        return false;
      }
      return true;
    }

    function checkYear(year: string) {
      console.log("年份：",year);
      if (isNaN(parseInt(year))) {
        Toast({
          type: "warning",
          title: "标签批号年份输入有误,请重新输入！",
        });
        return false;
      }
      const now = new Date();
      const currentYear = now.getFullYear();
      const lastFiveYear = currentYear-5;
      console.log(lastFiveYear+"--"+currentYear);
      if (parseInt(year) < lastFiveYear || parseInt(year) > currentYear) {
        Toast({
          type: "warning",
          title: "标签批号年份应该在"+lastFiveYear+"-"+currentYear+"之间！",
        });
        return false;
      }
      return true;
    }

    function checkMonth(month: string) {
      if (isNaN(parseInt(month, 10))) {
        Toast({
          type: "warning",
          title: "标签批号月份输入有误,请重新输入！",
        });
        return false;
      } else if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
        Toast({
          type: "warning",
          title: "标签批号月份应该在1-12之间！",
        });
        return false;
      }
      return true;
    }

    function checkDay(day: string) {
      if (isNaN(parseInt(day, 10))) {
        Toast({
          type: "warning",
          title: "标签批号日期输入有误,请重新输入！",
        });
        return false;
      } else if (parseInt(day, 10) < 1 || parseInt(day, 10) > 31) {
        Toast({
          type: "warning",
          title: "标签批号日期应该在1-31之间！",
        });
        return false;
      }
      return true;
    }

    function checkPlanNo(planNo: string) {
      console.log("计划号：",planNo);
      if(isNaN(parseInt(planNo))){
        Toast({
          type: "warning",
          title: "标签批号输入有误,请重新输入！",
        });
        return false;
      }
      return true;
    }

    function downloadCert(productNum: string,batchNum: string) {
      //组装参数
      const data = qs.stringify({
        productNum: productNum,
        batchNum: batchNum,
      });
      //请求下载证书
      axios.post("/M/Product/checkProductCert", data,{})
              .then((res) => {
                console.log("res:",res)
                if (res.data.success) {
                  //下载证书
                  const link = document.createElement('a');
                  link.href = res.data.link;
                  link.setAttribute('download', res.data.fileName);
                  link.click();
                  //关闭弹窗
                  //this.showModal = false;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                Toast({
                  type: "error",
                  title: err.message || err
                });
              });
    }

    function download(){
      console.log("batchNo:",batchNo.value)
      //校验参数
      if(!batchNo.value||batchNo.value==""){
        Toast({
          type: "warning",
          title: "请输入正确的标签批号",
        });
        return false;
      }
      if (!checkBatchNum(batchNo.value)) {
        return false;
      }

      const years = batchNo.value.substring(0, 4);
      const moneys = batchNo.value.substring(4, 6);
      const days = batchNo.value.substring(6, 8);
      if (batchNo.value.length==10&&checkYear(years) && checkMonth(moneys) && checkDay(days) && checkPlanNo(batchNo.value.substring(8, 10))) {
        //下载证书
        downloadCert(catNo.value,batchNo.value);
        this.showModal = false;
      }
      if (batchNo.value.length==8&&checkYear(years) && checkMonth(moneys) && checkDay(days)) {
        //下载证书
        downloadCert(catNo.value,batchNo.value);
        this.showModal = false;
      }
    }

   function  downloadcertificate(){
      console.log(this.showModal,1111)
      if ((productsVO.value as any).suppliercode == 1) {//伟业产品
        this.showModal = true;
      }else{
        //非伟业产品
        downloadCert(catNo.value,batchNo.value);
        this.showModal = false;
      }
    }

      watch(
              [
                () => {
                  return route.query;
                },
                () => {
                  return route.params;
                },
              ],
              (to, from) => {
                // 避免登录时触发页面刷新
                console.log(to, from);
                if (to[1].id && !from[1].valid && !from[1].validate) {
                  getData(to[1].id as string);
                }
              }
      );

      watch(
              () => {
                return shareTitle.value
              },
              (to, from) => {
                const isweixin = ref(false);
                const ua = navigator.userAgent.toLowerCase();
                const reg = /MicroMessenger/i;
                isweixin.value = reg.test(ua);
                if (isweixin.value) {
                  nextTick(() => {
                    const link = window.location.href;
                    const title = document.title;
                    const desc = (document.querySelectorAll(
                            "meta[name=description]"
                    )[0] as any).content;
                    console.log(desc);

                    const formData = new FormData();
                    formData.append("url", link);
                    axios.post("/M/Server/getweixin", formData).then((res: any) => {
                      const data = res.data;
                      wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: data.appid, // 必填，公众号的唯一标识
                        timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                        nonceStr: data.nonceStr, // 必填，生成签名的随机串
                        signature: data.str, // 必填，签名
                        jsApiList: [
                          "checkJsApi",
                          "updateAppMessageShareData",
                          "updateTimelineShareData",
                        ], // 必填，需要使用的JS接口列表
                      });
                      wx.ready(function () {
                        //需在用户可能点击分享按钮前就先调用

                        wx.updateAppMessageShareData({
                          title: to+'-普天同创', // 分享标题
                          desc: route.meta.content.description, // 分享描述
                          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                          imgUrl: (productsVO.value as any).pic || "https://yingzegbw.oss-cn-beijing.aliyuncs.com/common/logo.png", // 分享图标
                          success: function () {
                            // 设置成功
                          },
                        });
                        wx.updateTimelineShareData({
                          title: to+'-普天同创', // 分享标题
                          desc: route.meta.content.description, // 分享描述
                          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                          imgUrl: (productsVO.value as any).pic || "https://yingzegbw.oss-cn-beijing.aliyuncs.com/common/logo.png", // 分享图标
                          success: function () {
                            // 设置成功
                          },
                        });
                      });
                    });
                  });
                }
              }
      )

      const loadOption = {
        text: "加载中...",
        color: "#b9135a",
        textColor: "#b9135a",
      };
      return {
        productsVO,
        list,
        amclass,
        comp,
        id,
        classId,
        isFav,
        RelatedProIdList,
        loadOption,
        secListComp,
        listCompLen,
        productLotList,
        clickSpec,
        specChecked,
        firstSpec,
        lotid,
        tcid,
        productPrice,
        biaowuPriceOpen,
        productPdfHref,
        productStock,
        showModal,
        calendarFormat,
        downloadcertificate,
        closeModal,
        download,
        downloadCert,
        catNo,
        suppliercode,
        batchNo
      };
    },
  });

